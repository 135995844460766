exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-index-tsx": () => import("./../../../src/templates/about/index.tsx" /* webpackChunkName: "component---src-templates-about-index-tsx" */),
  "component---src-templates-contact-index-tsx": () => import("./../../../src/templates/contact/index.tsx" /* webpackChunkName: "component---src-templates-contact-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-procedures-index-tsx": () => import("./../../../src/templates/procedures/index.tsx" /* webpackChunkName: "component---src-templates-procedures-index-tsx" */)
}

